"use client";
import ConnexNavPatternDesktop from "@/public/connex-nav-pattern-desktop.webp";
import DeveloperNavPatternDesktop from "@/public/developer-nav-pattern-desktop.webp";
import InnovationNavPatternDesktop from "@/public/innovation-nav-pattern-desktop.webp";

import Link from "next/link";
import PlusIcon from "../icons/plus-icon";
import TransitionLink from "@/components/transition-link";
import { motion } from "framer-motion";
import React, { forwardRef } from "react";
import { Transition } from "@headlessui/react";
import PlusIconSymbol from "@/public/plus-icon.svg";
import Image from "next/image";
import { cubicBezier } from "framer-motion/dom";

const PlusIconInnerComponent = React.forwardRef(
  function PlusIconInnerComponent(props, ref) {
    return (
      <Image
        // @ts-ignore
        ref={ref}
        src={PlusIconSymbol}
        alt="Plus Icon"
        width={20}
        height={20}
      />
    );
  },
);
const PlusIconInner = motion(PlusIconInnerComponent);

interface Props {
  title: string;
  description?: string;
  theme: "about" | "connex" | "innovation" | "developer";
  icon?: string;
  href: string;
  onNav: () => void;
}
export default function NavCard(props: Props) {
  const plusVariants = {
    hover: {
      transform: "rotate(90deg)",
      duration: 1,
    },
  };

  const cardVariants = {
    hover: {
      borderRadius: "25px",
    },
  };

  const cardBgVariants = {
    hover: {
      // scale: 1.05,
      clipPath: "border-box",
    },
  };

  const mvnxTransition = {
    duration: 1,
    ease: cubicBezier(0.5, 0.0, 0.0, 1.0),
  };
  return (
    <TransitionLink href={props.href} onNav={props.onNav}>
      <motion.div
        whileHover={["hover"]}
        transition={mvnxTransition}
        variants={cardVariants}
        style={{
          pathLength: 0.55,
          height: "220px",
          width: "288px",
        }}
        className={`  bg-gradient-to-b  text-white text-center justify-center flex flex-col rounded-4xl  ${
          props.theme === "about"
            ? "from-[#2D9FCA] to-[#0057BB]"
            : props.theme === "connex"
              ? "from-[#0458BB] to-[#5341BA]"
              : props.theme === "innovation"
                ? "from-[#B636A9] to-[#E27F44]"
                : props.theme === "developer"
                  ? "from-[#5D3FBA] to-[#B02CB7]"
                  : ""
        }`}
      >
        <motion.div
          variants={cardBgVariants}
          transition={mvnxTransition}
          style={{
            borderRadius: "50px",
            clipPath: "border-box",
            backgroundSize: "contain",
            backgroundImage:
              props.theme === "connex"
                ? `url(${ConnexNavPatternDesktop.src})`
                : props.theme === "innovation"
                  ? `url(${InnovationNavPatternDesktop.src})`
                  : props.theme === "developer"
                    ? `url(${DeveloperNavPatternDesktop.src})`
                    : "",
          }}
          className="flex flex-col w-full h-full  text-start p-7"

          //   style={{
          //     width: "353px",
          //     height: "415px",
          //     backgroundPositionX: "-29px",
          //     backgroundPositionY: "-151px",
          //     backgroundPosition: "center",
          //     backgroundSize: "cover",
          //     backgroundRepeat: "no-repeat",
          //     backgroundClip: "padding-box",
          //     backgroundImage: `${
          //       props.theme === "connex"
          //         ? `url(${ConnexNavPattern.src})`

          //         : props.theme === "innovation"
          //         ? `url(${InnovationNavPattern.src})`
          //         : props.theme === "developer"
          //         ? `url(${DeveloperNavPattern.src})`
          //         : ""
          //     }`,
          //   }}
        >
          <h1 className=" relative  text-body-semibold-mobile sm:text-body-semibold-desktop font-semibold">
            {props.title}
          </h1>
          <p className="text-body-regular-mobile sm:text-body-regular-desktop font-light">
            {props.description}
          </p>
          <div className="flex flex-1" />
          <PlusIconInner variants={plusVariants} transition={mvnxTransition} />
        </motion.div>
      </motion.div>
    </TransitionLink>
  );
}
