export const CopyConfig = {
  landing_hero_transitions: ["Go", "Far", "Fast", "Connect to what's next"],
  navigation: {
    about: {
      title: "About",
      description: "Connect to what's next.",
    },
    connex: {
      title: "ConnEX",
      description: "Go far, fast.",
    },
    developer: {
      title: "Developer Platform",
      description: "Don't just dream it.",
    },
    innovation: {
      title: "Innovation Lab",
      description: "It starts with an idea.",
    },
  },
  about: {
    hero: {
      title: "MVNx",
      subtitle:
        "Your vision. America's most reliable 5G. Create without limits.", // Your vision. America's most reliable 5G. Create without limits.
    },
    logoTransition: {
      first:
        "288M reasons to say hello to supercharged 5G. AT&T connects you to America, coast to coast.", //     "288M reasons to say hello to supercharged 5G. AT&T connects you to America, coast to coast."
      second: "Breathe easy. ConnEX manages your network & compliance, 24/7.",
      // "Breathe easy. ConnEX manages your network & compliance, 24/7.",
      third:
        "Kiss boring apps goodbye. Ignite innovation with our cutting-edge 5G APIs.", // Kiss boring apps goodbye. Ignite innovation with our cutting-edge 5G APIs.
      fourth:
        "Think it. Build it. Together. Our experts co-create your dream solution, custom or ready-made.", // Think it. Build it. Together. Our experts co-create your dream solution, custom or ready-made.
    },
    products_1: {
      title:
        "Plug into the future, seamlessly. Unbreakable connections by ConnEX.", // Plug into the future, seamlessly. Unbreakable connections by ConnEX.
    },
    products_2: {
      cards: {
        connex: {
          title: "ConnEX",
          callout: "Go far, fast",
          hover:
            "Tired of the status quo? Elevate your marketplace with our game-changing suite. It's time for a customer journey revolution.", // Tired of the status quo? Elevate your marketplace with our game-changing suite. It's time for a customer journey revolution.
        },
        developer: {
          title: "Developer Platform",
          callout: "Don't just dream it",
          hover:
            "Stop fighting APIs and frameworks. Get the tools, integrations, and documentation you need to focus on what matters.", // Stop fighting APIs and frameworks. Get the tools, integrations, and documentation you need to focus on what matters.
        },
        innovation: {
          title: "Innovation Lab",
          callout: "It starts with an idea",
          hover:
            "Your innovation journey starts here: Lab solutions for every stage.", // Your innovation journey starts here: Lab solutions for every stage.
        },
      },
    },
  },
  contact: {
    title: "Let's connect",
    description: "Customize the perfect fit for your business",
  },
  connex: {
    hero: {
      heading1: "Launch",
      heading2: "ConnEX",
    },
    keyMessaging: [
      "Network woes? Solved. We optimize your network for speed, reliability, and security.",
      "Compliance headaches? Gone. Stay informed and compliant with automated checks and expert guidance.",
      "Business bottlenecks? Broken. Streamline operations with automation and intelligent tools.",
      "Growth barriers? Removed. Scale seamlessly with solutions that adapt to your evolving needs.",
    ],
    topFeatures: [
      {
        title:
          "Network Chaos Got You Down? Embrace Flawless Coordination with ConnEX.",
        image: "",
      },
      {
        title:
          "Launch Like a Rocket. Scale with Confidence. Partner with AT&T MVNx.",
        image: "",
      },
    ],
    article: {
      badge: "Article",
      title: "Placeholder headline for ConnEX related resources goes here",
      image: "",
    },
  },
  developer: {
    hero: {
      heading1: "Build",
      heading2: "Developer Platform",
    },
    keyMessaging: [
      "Blast off to telecom innovation: Let our APIs be your launchpad.",
      "Don't code the future, create it: Uncover the power of our telecom API suite.",

      "Break through limitations, build without boundaries.",
      "Turn ideas into reality, code into cash: Monetize your innovation with our ecosystem.",
    ],
    topFeatures: [
      {
        title: "Break boundaries, defy limitations",
        image: "",
      },
      {
        title: "Leave the map behind, explore uncharted territory",
        image: "",
      },
    ],
    article: {
      badge: "Article",
      title: "Placeholder headline for ConnEX related resources goes here",
      image: "",
    },
  },
  innovation: {
    hero: {
      heading1: "Inspire",
      heading2: "Innovation Lab",
    },
    keyMessaging: [
      "Blast off to innovation: Let our 5G studio be your rocket launchpad.",
      "Don't wait, innovate: Unleash your ideas in the MVNx Innovation Lab.",

      "Break down barriers, build the future: Experience the power of cutting-edge technology.",

      "Turn ideas into reality, imagination into impact: Join the MVNx Innovation Lab movement.",
    ],
    topFeatures: [
      {
        title:
          "Beyond Likes and Shares: Discover Monetization Secrets the Big Stars Know.",
        image: "",
      },
      {
        title:
          "Where Imagination Takes Flight: Dive into Our Lab of Unbridled Innovation",
        image: "",
      },
    ],
    article: {
      badge: "Article",
      title: "Placeholder headline for ConnEX related resources goes here",
      image: "",
    },
  },
  resources: {
    hero: {
      contentType: "Article",
      title: "The NBA uses AT&T 5G HoloVision to power a surprise meeting",
    },
    content: [
      {
        type: "Video",
        title: "AT&T explores Uber applications for 5G in air taxis and drones",
      },
      {
        type: "Article",
        title:
          "AT&T collaborates with Ericsson to accelerate Open RAN in the U.S.",
      },
      {
        type: "Article",
        title: "How AT&T 5G helps deaf athletes and sports communications",
      },
      {
        type: "Press Release",
        title:
          "AT&T collaborates with Ericsson to accelerate Open RAN in the U.S.",
      },
    ],
  },
};

export const CopyConfigCathy = {
  landing_hero_transitions: ["Go", "Far", "Fast", "Connect to what's next"],
  navigation: {
    about: {
      title: "About",
      description: "Connect to what's next.",
    },
    connex: {
      title: "ConnEX",
      description: "Go far, fast.",
    },
    developer: {
      title: "Developer Platform",
      description: "Don't just dream it.",
    },
    innovation: {
      title: "Innovation Lab",
      description: "It starts with an idea.",
    },
  },
  about: {
    hero: {
      title: "AT&T MVNx",
      subtitle:
        "The future is now... Transformative Innovation... Endless possibilities ",
    },
    logoTransition: {
      first:
        "Align your business with the most awarded brand in the industry - AT&T MVNx... setting a new precedent",
      second:
        "Connect with AT&T's Nationwide 5G Network, serving over 241 million subscribers, in 24,000 U.S. cities and towns.",
      third:
        "Conceive it. Build it. Together. Co-create your dream solution with our experts, custom or ready-made",
      fourth:
        "Cultivate fresh ideas in a collaborative environment - Discover novel ways to monetize",
    },
    products_1: {
      title: "AT&T MVNx </br> Setting the standard telecom leaders follow",
    },
    products_2: {
      cards: {
        connex: {
          title: "ConnEX",
          callout: "Go far, fast",
          hover:
            "Elevate your marketplace with our game changing suite of solutions.",
        },
        developer: {
          title: "Developer Platform",
          callout: "Don't just dream it",
          hover:
            "Step into the future of </br> innovative telecom. </br> Simplify. Integrate. Monetize.",
        },
        innovation: {
          title: "Innovation Lab",
          callout: "It starts with an idea",
          hover:
            "Kiss boring apps goodbye! Ignite innovation with our cutting edge 5G APIs.",
        },
      },
    },
  },
  contact: {
    title: "Let's connect",
    description: "Customize the perfect fit for your business",
  },
  connex: {
    hero: {
      heading1: "ConnEX",
      heading2: "Power your connectivity with the advanced ConnEX Platform",
    },
    keyMessaging: [
      "Go far, fast",
      "Innovative, ready-made </br> solutions... customized to </br> support your business growth </br> and all that lies ahead",
      "Next-gen AI, enabling proactive observability",
      "From Network and Operations, </br> to Product and Compliance, </br>ConnEX is the answer",
    ],
    topFeatures: [
      {
        title: "Flawlessly coordinated solutions, empowered by ConnEX", // Network Chaos Got You Down? Embrace Flawless Coordination with ConnEX.
        image: "",
      },
      {
        title: "AT&T MVNx – Driving positive transformation", // Launch Like a Rocket. Scale with Confidence. Partner with AT&T MVNx.
        image: "",
      },
    ],
    article: {
      badge: "Article",
      title: "Placeholder headline for ConnEX related resources goes here",
      image: "",
    },
  },
  developer: {
    hero: {
      heading1: "Developer Platform",
      heading2: "Harness the power of our emerging 5G APIs",
    },
    keyMessaging: [
      "Step into the future of innovative telecom", // Blast off to telecom innovation: Let our APIs be your launchpad.
      "Simplify. Integrate. Monetize.", // Don't code the future, create it: Uncover the power of our telecom API suite.

      "A robust set of APIs to create customized network solutions ", // Break through limitations, build without boundaries: Explore our robust and flexible APIs.
      "Target your consumer markets... Meet your front and back office needs", // Turn ideas into reality, code into cash: Monetize your innovation with our API ecosystem.
    ],
    topFeatures: [
      {
        title: "AT&T MVNx… pioneering a path through innovation", // Break boundaries, defy limitations
        image: "",
      },
      {
        title: "AT&T MVNx APIs... Powering the Innovators", // Leave the map behind, explore uncharted territory
        image: "",
      },
    ],
    article: {
      badge: "Article",
      title: "Placeholder headline for ConnEX related resources goes here",
      image: "",
    },
  },
  innovation: {
    hero: {
      heading1: "Innovation Lab",
      heading2:
        "AT&T MVNx </br>Igniting Innovation <br/>Inspiring the Visionary",
    },
    keyMessaging: [
      "It starts with an idea", // Blast off to innovation: Let our 5G studio be your rocket launchpad.
      "Heraclitus once said, </br> “Change is the only constant.” </br>AT&T MVNx – </br>Champions of change", // Don't wait, innovate: Unleash your ideas in the MVNx Innovation Lab.

      "Elevate your creative concepts to </br> new heights; engage with our </br> talented 5G studio", // Break down barriers, build the future: Experience the power of cutting-edge technology at our lab.

      "Simplify. Transform. Reinvent. </br> AT&T MVNx… </br>Modern day change agents", // Turn ideas into reality, imagination into impact: Join the MVNx Innovation Lab movement.
    ],
    topFeatures: [
      {
        title: "Attract ingenuity and discover creative ways to monetize", // Beyond Likes and Shares: Discover Monetization Secrets the Big Stars Know.
        image: "",
      },
      {
        title:
          "A playground for thinkers, creators, and innovators, fostering a culture of experimentation", // Where Imagination Takes Flight: Dive into Our Lab of Unbridled Innovation
        image: "",
      },
    ],
    article: {
      badge: "Article",
      title: "Placeholder headline for ConnEX related resources goes here",
      image: "",
    },
  },
  resources: {
    hero: {
      contentType: "Article",
      title: "The NBA uses AT&T 5G HoloVision to power a surprise meeting",
      href: "https://about.att.com/story/2020/nba_draft_att_5g_holovision.html",
    },
    content: [
      {
        type: "Video",
        title: "AT&T explores Uber applications for 5G in air taxis and drones",
        href: "https://about.att.com/story/2019/att_and_uber.html",
      },
      {
        type: "Article",
        title:
          "AT&T collaborates with Ericsson to accelerate Open RAN in the U.S.",
        href: "https://about.att.com/story/2023/commercial-scale-open-radio-access-network.html",
      },
      {
        type: "Article",
        title: "How AT&T 5G helps deaf athletes and sports communications",
        href: "https://about.att.com/story/2023/5g-helmet.html",
      },
      {
        type: "Article",
        title: "AT&T and Rivian team up to reduce CO2 Emissions",
        href: "https://about.att.com/story/2023/rivian-commercial-fleet-pilot.html",
      },
    ],
  },
};
