"use client";
import Link from "next/link";
import PlusIcon from "../icons/plus-icon";
import ConnexNavPattern from "@/public/mobile-nav-connex-bg.webp";
import DeveloperNavPattern from "@/public/mobile-nav-developer-bg.webp";
import InnovationNavPattern from "@/public/mobile-nav-innovation-bg.webp";

interface Props {
  title: string;
  theme: "about" | "connex" | "innovation" | "developer";
  href: string;
  onNav: () => void;
}
export default function MobileNavCard(props: Props) {
  return (
    <Link href={props.href} className=" min-w-max" onClick={props.onNav}>
      <div
        className={`h-12  text-cta-forms-mobile  flex  rounded-full bg-clip-padding bg-gradient-to-b text-white  ${
          props.theme === "about"
            ? "from-[#2D9FCA] to-[#0057BB]"
            : props.theme === "connex"
              ? "from-[#0458BB] to-[#5341BA]"
              : props.theme === "innovation"
                ? "from-[#5D3FBA] to-[#B02CB7]"
                : props.theme === "developer"
                  ? "from-[#B636A9] to-[#E27F44]"
                  : ""
        }`}
      >
        <div
          className="px-4 py-2 w-full h-full text-center justify-center flex flex-col "
          style={{
            borderRadius: "50px",
            clipPath: "border-box",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage:
              props.theme === "connex"
                ? `url(${ConnexNavPattern.src})`
                : props.theme === "innovation"
                  ? `url(${InnovationNavPattern.src})`
                  : props.theme === "developer"
                    ? `url(${DeveloperNavPattern.src})`
                    : "",
          }}
        >
          {props.title}
        </div>
      </div>
    </Link>
  );
}
