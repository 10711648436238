"use client";
import { usePathname } from "next/navigation";
import { forwardRef } from "react";
import { Transition } from "@headlessui/react";
import MobileNavCard from "@/components/nav/mobile-nav-card";
import MobileButton from "@/components/nav/mobile-button";
import { useStore } from "@/app/store";

const themes = [
  {
    uri: "/",
    color: "#0057BB",
  },
  {
    uri: "/connex",
    color: "#394ABB",
  },
  {
    uri: "/developer-platform",
    color: "#5E40BB",
  },
  {
    uri: "/innovation-lab",
    color: "#B737AA",
  },
];

// Arrow functions cannot be used here because eslint will scream at you.
// Exporting an arrow function directly doesn't give the component a displayName, but if
// you export a regular function, its name will be used as that param.
const MobileNavbar = forwardRef(function MobileNavbar(props, ref) {
  const { setNavbarExpanded } = useStore();
  {
    /* TODO: Make the nav button a part of the whole thing so it has the same animation path and timing */
  }
  return (
    <div
      // @ts-ignore
      ref={ref}
      className="lg:hidden bg-white h-16 w-full p-2 flex flex-row gap-2 overflow-x-scroll z-[50] fixed"
      style={{
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      {/* TODO: Re-add trademark if granted */}

      <MobileNavCard
        href="/about"
        theme="about"
        title="About mvnx"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileNavCard
        href="/connex"
        theme="connex"
        title="ConnEX"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileNavCard
        href="/developer-platform"
        theme="developer"
        title="Developer Platform"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileNavCard
        href="/innovation-lab"
        theme="innovation"
        title="Innovation Lab"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileButton
        href="/resources"
        style="button"
        title="Resources"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileButton
        href="/contact"
        style="button"
        title="Contact Us"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileButton
        href="https://about.att.com/privacy.html"
        style="link"
        title="Privacy Policy"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileButton
        href="https://www.att.com/legal/terms.attWebsiteTermsOfUse.html"
        style="link"
        title="Terms & Conditions"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
      <MobileButton
        href="#"
        style="plain"
        title="© 2024 AT&T mvnx"
        onNav={() => {
          setNavbarExpanded(false);
        }}
      />
    </div>
  );
});

export default function MobileNav() {
  const pathname = usePathname();

  const { navbarExpanded, setNavbarExpanded } = useStore();

  if (pathname === "/" && !navbarExpanded) {
    //setExpanded(true);
  }

  return (
    <Transition
      show={navbarExpanded}
      enter={`transition-all ease-mvnx-in-out duration-1000 ${
        pathname !== "/" ? "" : "delay-1000"
      }`}
      enterFrom="translate-y-80"
      enterTo="translate-y-0"
      leave="transition-all ease-mvnx-in-out duration-[2250ms]"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-80"
      as={MobileNavbar}
    />
  );
}
