"use client";

import { ReactNode } from "react";
import { usePathname } from "next/navigation";
import MenuButton from "@/components/nav/menu-button";
import Image from "next/image";
import MVNXLogo from "@/public/mvnx-logo.svg";
import { useStore } from "@/app/store";
import { motion } from "framer-motion";
import { cubicBezier } from "framer-motion/dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { MvnxMotionEase } from "@/components/animations";

export default function NavLayoutFluidWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const pathname = usePathname();

  const { navbarExpanded, setNavbarExpanded, globalObserverInUse } = useStore();

  const widthClasses =
    pathname === "/"
      ? "max-w-fluid-wrapper-mobile w-fluid-wrapper-mobile lg:max-w-fluid-wrapper-home lg:w-fluid-wrapper-home"
      : "max-w-fluid-wrapper-mobile w-fluid-wrapper-mobile lg:max-w-fluid-wrapper lg:w-fluid-wrapper";

  const theme =
    pathname === "/connex"
      ? "connex"
      : pathname === "/developer-platform"
        ? "developer"
        : pathname === "/innovation-lab"
          ? "innovation"
          : "mvnx";

  useGSAP(() => {
    if (typeof window !== "undefined" && window.innerWidth > 1024) {
      if (navbarExpanded) {
        gsap.to("[data-sidebar-pushout]", {
          x: -238,
          delay: 1,
          duration: 1,
          ease: MvnxMotionEase,
        });
      } else {
        gsap.to("[data-sidebar-pushout]", {
          x: 0,
          delay: 1,
          duration: 1,
          ease: MvnxMotionEase,
        });
      }
    }
  }, [navbarExpanded]);

  return (
    <>
      <div
        className={`${widthClasses} z-30 ${["/about", "/"].includes(pathname) ? "overflow-y-clip" : ""}`}
      >
        <div
        /*className={
            pathname !== "/"
              ? "lg:transition-all lg:ease-in-out lg:delay-1000 lg:duration-1000" +
                (navbarExpanded ? " lg:-translate-x-[238px]" : "")
              : ""
          }*/
        >
          {navbarExpanded && pathname !== "/" && (
            <div
              className="fixed inset-0 transition-all ease-in-out duration-1000 bg-black/30 lg:bg-transparent z-[99]"
              onClick={() => setNavbarExpanded(false)}
            />
          )}
          <motion.div
            initial={{
              y: -100,
            }}
            animate={{
              y: 0,
              transition: {
                delay: 1.25,
                duration: 1,
                ease: cubicBezier(0.5, 0.0, 0.0, 1.0),
              },
            }}
            className={`lg:hidden  w-full pt-2 pl-2 ${
              pathname === "/about" ? "hidden" : "block"
            }`}
          >
            <MenuButton
              theme={theme}
              fullwidth={true}
              content={
                <div className={` flex-row justify-center align-middle flex`}>
                  <Image
                    priority={true}
                    src={MVNXLogo}
                    alt={""}
                    height={24}
                    width={112}
                  />
                </div>
              }
              href={"/"}
              onNav={() => {
                if (pathname !== "/") setNavbarExpanded(false);
              }}
            />
          </motion.div>
          <div id="global-observer-target">{children}</div>
        </div>
      </div>
    </>
  );
}
