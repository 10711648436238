"use client";
import Developer from "@/public/developer-header-img-tmp.svg";
import Image from "next/image";
import { useState } from "react";
export default function ComingSoon() {
  const [email, setEmail] = useState<string>("");
  return (
    <div className="  w-full h-dvh  md:p-2">
      <div className=" bg-mvnx-primary w-full h-full p-4 md:rounded-4xl ">
        <div className=" text-white h-full gap-2 pb-12    text-h1-mobile lg:text-h2-desktop flex  flex-col-reverse xl:flex-row justify-center items-center ">
          <div className="h-full w-full max-w-md min-w-[30%] pt-[20%] gap-2 flex flex-col items-center">
            Coming Soon
            <div className=" text-center text-cta-forms-mobile opacity-80">
              Be the first to know when we launch our new website
            </div>
            <div className="w-[100%] md:w-[70%] align-center gap-2  flex flex-col    ">
              <input
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                className={` focus:outline-none mt-5  w-full focus:ring-1 ring-white ring-opacity-50 rounded-[15px] max-h-11 flex-1 placeholder-white text-cta-forms-mobile text-white p-4 bg-opacity-90  bg-[#0292D5] autofill:bg-[#0292D5]`}
                placeholder="Email"
              />
              <button
                data-sitekey="your_site_key"
                data-callback="onSubmit"
                aria-label="Submit button"
                onClick={() => {
                }}
                className={`g-recaptcha focus:outline-none focus:ring-0 ring-white ring-opacity-50 bg-white text-cta-forms-desktop text- rounded-4xl w-full  h-12 text-[#0292D5]`}
                //   type="submit"
              >
                Sign up
              </button>
              {/* <button className="  bg-[#0292D5] p-1  mt-5 rounded-[15px] h-11 w-11 flex flex-row items-center justify-center text-cta-forms-mobile md:text-cta-forms-desktop">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="rgba(255,255,255,1)"
            >
              <path d="M20 17H22V19H2V17H4V10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10V17ZM9 21H15V23H9V21Z"></path>
            </svg>
          </button> */}
            </div>
          </div>
          <Image
            priority={true}
            src={Developer}
            alt={""}
            className="max-w-[80%]  h-auto md:max-w-[60%] lg:max-w-[90%] mt-[30%] lg:mt-[0%]"
          />
        </div>
      </div>
    </div>
  );
}
