import Link from "next/link";

export function Legal() {
  return (
    <section className="flex flex-col gap-1 text-center w-[288px] h-[102px] mt-4">
      <Link
        href={"https://about.att.com/privacy.html"}
        className="text-mvnx-primary underline"
      >
        Privacy Policy
      </Link>
      <Link
        href={"https://www.att.com/legal/terms.attWebsiteTermsOfUse.html"}
        className="text-mvnx-primary underline"
      >
        Terms & Conditions
      </Link>
      {/* TODO: Re-add trademark if granted */}
      <p className="text-mvnx-primary">
        ©{new Date().getFullYear()} AT&T mvnx
      </p>
    </section>
  );
}
