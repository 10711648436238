import { usePathname } from "next/navigation";
import { Observer } from "gsap/Observer";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useStore } from "@/app/store";
import { MvnxMotionEase } from "@/components/animations";
import CSSRulePlugin from "gsap/CSSRulePlugin";

let observer: Observer | null = null;

const observerDefaults = {
  type: "wheel, touch, scroll", // Scroll event types listened to
  tolerance: 10, // Scrolling past this amount of pixels will trigger the observer hooks
  target: "#global-observer-target", // Scrolling within this DOM element will trigger hooks
};

const pageSectionAmounts: { [key: string]: number } = {
  "/": 0,
  "/about": 4, // ATT Logo, launch build inspire, product section (with cards), contact
};

enum ScrollDirection {
  UP,
  RIGHT,
  DOWN,
  LEFT,
}

let handlingScroll = false;

function handleScroll(direction: ScrollDirection, pathname: string) {
  if (handlingScroll) return;
  if (!useStore.getState().globalScrollReleased) return;
  handlingScroll = true;
  let nextSectionId = useStore.getState().globalCurrentSection;

  switch (direction) {
    case ScrollDirection.UP:
    case ScrollDirection.LEFT:
      if (nextSectionId == 1) {
        handlingScroll = false;
        return;
      }
      --nextSectionId;
      break;
    case ScrollDirection.DOWN:
    case ScrollDirection.RIGHT:
      if (nextSectionId == pageSectionAmounts[pathname]) {
        handlingScroll = false;
        return;
      }
      ++nextSectionId;
      break;
    default:
      throw new Error(
        `Unsupported scroll direction for global observer (${direction})`,
      );
  }

  console.info("NEXT SECTION " + nextSectionId);

  switch (pathname) {
    case "/about":
      if (nextSectionId == 1) {
        console.log("Executing section 1");
        const tl = gsap.timeline();
        tl.fromTo(
          "[data-observer-id='2']",
          {
            opacity: 1,
            ease: MvnxMotionEase,
          },
          {
            opacity: 0,
            duration: 1,
            ease: MvnxMotionEase,
          },
        );
        tl.to(
          "[data-observer-id='2']",
          {
            marginTop: "0",
            duration: 0,
          },
          "<+=1",
        );
        tl.fromTo(
          "[data-observer-id='1'] #in, [data-observer-id='1'] #shape-innovation",
          {},
          {
            y: "0",
            opacity: 1,
            duration: 1,
            ease: MvnxMotionEase,
          },
          "<-=1",
        );
        tl.play().eventCallback("onComplete", () => {
          tl.kill();
          handlingScroll = false;
          useStore.setState({
            globalCurrentSection: nextSectionId,
            globalScrollReleased: false,
          });
          return;
        });
      }
      if (nextSectionId == 2) {
        console.log("Executing section 2");

        const tl = gsap.timeline();
        tl.set("[data-observer-id='2']", {
          marginTop: "calc(-100dvh)",
          opacity: 0,
        });
        tl.to(
          "[data-observer-id='2']",
          {
            opacity: 1,
            duration: 1,
            ease: MvnxMotionEase,
          },
          "<",
        );
        if (direction === ScrollDirection.DOWN) {
          tl.set(
            CSSRulePlugin.getRule("#lbi-hero-parent::after"),
            {
              cssRule: {
                opacity: 0,
              },
            },
            "<",
          );
          tl.fromTo(
            "[data-observer-id='1'] #in, [data-observer-id='1'] #shape-innovation",
            {},
            {
              y: "-500",
              opacity: 0,
              duration: 1,
              ease: MvnxMotionEase,
            },
            "<",
          );
        }

        if (direction === ScrollDirection.UP) {
          tl.fromTo(
            "#word-container",
            {
              xPercent: -50,
            },
            {
              xPercent: 0,
              duration: 2,
              ease: MvnxMotionEase,
            },
            "<",
          );
          tl.fromTo(
            CSSRulePlugin.getRule("#lbi-hero-parent::after"),
            {
              cssRule: {
                opacity: 0.5,
              },
              duration: 1.5,
              ease: MvnxMotionEase,
            },
            {
              cssRule: {
                opacity: 0,
              },
              duration: 1.5,
              ease: MvnxMotionEase,
            },
            "<",
          );

          tl.fromTo(
            "[data-observer-id='3']",
            {
              ease: MvnxMotionEase,
              marginTop: "calc(-100dvh - 50px)",
            },
            {
              marginTop: "0",
              duration: 1.5,
              ease: MvnxMotionEase,
            },
            "<",
          );
        }

        tl.play().eventCallback("onComplete", () => {
          tl.kill();
          handlingScroll = false;
          useStore.setState({
            globalCurrentSection: nextSectionId,
            globalScrollReleased: false,
          });
          return;
        });
      }

      if (nextSectionId == 3) {
        const tl = gsap.timeline();
        if (direction === ScrollDirection.DOWN) {
          tl.fromTo(
            "#word-container",
            {
              xPercent: 0,
            },
            {
              xPercent: -50,
              duration: 2,
              ease: MvnxMotionEase,
            },
          );
          tl.to(
            CSSRulePlugin.getRule("#lbi-hero-parent::after"),
            {
              cssRule: {
                opacity: 0.5,
              },
              duration: 1.5,
              ease: MvnxMotionEase,
            },
            "<",
          );
        }

        tl.fromTo(
          "[data-observer-id='3']",
          {
            ease: MvnxMotionEase,
          },
          {
            marginTop: "calc(-100dvh - 50px)",
            duration: 1.5,
            ease: MvnxMotionEase,
          },
          "<",
        );

        if (direction === ScrollDirection.UP) {
          tl.fromTo(
            "#contact-us-parent",
            {
              ease: MvnxMotionEase,
              marginTop: "calc(-200dvh + 10px)",
            },
            {
              marginTop: "0",
              duration: 1.5,
              ease: MvnxMotionEase,
            },
            "<",
          );
          tl.to(
            CSSRulePlugin.getRule("#prod-section-parent::after"),
            {
              cssRule: {
                opacity: 0,
                marginTop: "0",
              },
              duration: 1,
            },
            "<",
          );
        }

        tl.play().eventCallback("onComplete", () => {
          tl.kill();
          handlingScroll = false;
          useStore.setState({
            globalCurrentSection: nextSectionId,
            globalScrollReleased: false,
          });
          return;
        });
      }

      if (nextSectionId == 4) {
        const tl = gsap.timeline();
        tl.fromTo(
          "#contact-us-parent",
          {
            ease: MvnxMotionEase,
          },
          {
            marginTop: "calc(-200dvh + 10px)",
            duration: 1.5,
            ease: MvnxMotionEase,
          },
          "<",
        );
        tl.to(
          CSSRulePlugin.getRule("#prod-section-parent::after"),
          {
            cssRule: {
              opacity: 1,
              marginTop: "calc(-200dvh)",
            },
            duration: 1,
          },
          "<+0.5",
        );
        tl.play().eventCallback("onComplete", () => {
          tl.kill();
          handlingScroll = false;
          useStore.setState({
            globalCurrentSection: nextSectionId,
            globalScrollReleased: true, // Allow scroll as there's nothing to go further to
          });
          return;
        });
      }
      break;
  }
}

export default function GlobalObserver() {
  const pathname = usePathname();
  const { globalScrollReleased, globalCurrentSection, setGlobalObserverInUse } =
    useStore();

  // GSAP will complain if you don't register it
  gsap.registerPlugin(Observer);
  gsap.registerPlugin(CSSRulePlugin);

  // This will run when pathname changes
  useGSAP(() => {
    // Kill obs if we already have one
    if (observer !== null) {
      observer.kill();
      console.info(`[🌍 Observer] Killed previous instance`);
    }

    if (!pageSectionAmounts[pathname]) {
      console.warn(
        "[🌍 Observer] Configuration for this path name does not exist. Observer will not be spawned",
      );
      // Notify the store
      setGlobalObserverInUse(false);
      return;
    }

    // Start measuring
    const start = new Date().getTime();
    console.info(
      `[🌍 Observer] Init`,
      pathname,
      pageSectionAmounts[pathname],
      globalCurrentSection,
    );

    // Boot up
    observer = Observer.create({
      type: observerDefaults.type,
      // All scroll methods are inverted for non-PC devices. We then multiply the wheel speed by -1 to invert the direction.
      onDown: () =>
        !handlingScroll && handleScroll(ScrollDirection.UP, pathname),
      onUp: () =>
        !handlingScroll && handleScroll(ScrollDirection.DOWN, pathname),
      onLeft: () =>
        !handlingScroll && handleScroll(ScrollDirection.DOWN, pathname),
      onRight: () =>
        !handlingScroll && handleScroll(ScrollDirection.UP, pathname),
      tolerance: observerDefaults.tolerance,
      preventDefault: true,
      wheelSpeed: -1,
      target: observerDefaults.target,
    });

    // Notify the store
    setGlobalObserverInUse(true);

    // How fast was it? 10/10, great experience, would do it again
    const elapsedMs = (new Date().getTime() - start) / 10000;
    console.info(`[🌍 Observer] Initialized in ${elapsedMs}ms`, pathname);
  }, [globalScrollReleased, globalCurrentSection, pathname]);

  // No HTML needed
  return null;
}
