import { ReactNode } from "react";
import TransitionLink from "@/components/transition-link";

interface Props {
  content: ReactNode;
  fullwidth?: boolean;
  href: string;
  theme: "mvnx" | "neutral" | "connex" | "innovation" | "developer";
  onNav: () => void;
}
export default function MenuButton(props: Props) {
  return (
    <TransitionLink href={props.href} onNav={props.onNav}>
      <div
        className={`menu-button text-white text-center justify-center flex flex-col ${
          props.fullwidth ? "w-full" : "w-[288px]"
        } h-14 transition-all duration-1000 ease-mvnx-in-out rounded-[35px] hover:rounded-[25px] ${
          props.theme === "mvnx"
            ? "bg-mvnx-primary"
            : props.theme === "neutral"
              ? "bg-mvnx-neutral"
              : props.theme === "connex"
                ? "bg-connex"
                : props.theme === "innovation"
                  ? "bg-innovation"
                  : props.theme === "developer"
                    ? "bg-developer"
                    : ""
        } lg:bg-mvnx-primary`}
      >
        {props.content}
      </div>
    </TransitionLink>
  );
}
