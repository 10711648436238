import Link from "next/link";
import { ReactNode } from "react";
import TransitionLink from "@/components/transition-link";

interface Props {
  title: string;
  href: string;
  style: "plain" | "link" | "button";
  onNav: () => void;
}
export default function MobileButton(props: Props) {
  return (
    <TransitionLink href={props.href}  onNav={() => {
      props.onNav()
    }}>
      {props.style === "button" && (
        <div
        
          className={`h-12 px-4 min-w-max py-2 rounded-full bg-[#BEEDFF] text-mvnx-primary text-center justify-center flex flex-col`}
        >
          {props.title}
        </div>
      )}

      {props.style === "link" && (
        <div
          className={`h-12 px-2 min-w-max py-2 underline  text-mvnx-primary text-center justify-center flex flex-col`}
        >
          {props.title}
        </div>
      )}

      {props.style === "plain" && (
        <div
          className={`h-12 px-2 min-w-max py-2 text-mvnx-primary text-center justify-center flex flex-col`}
        >
          {props.title}
        </div>
      )}
    </TransitionLink>
  );
}
