export default function MenuIcon({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 12 16"
      width={10.5}
      height={16}
      fill={color || "#000000"}
      className={className}
    >
      <path d="M.5 0h3v16h-3zM8.5 0h3v16h-3z" />
    </svg>
  );
}
