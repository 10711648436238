export default function MvnxIcon({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.998 88"
      fill={color || "#000000"}
      className={className}
      width={18}
      height={88}
    >
      <path d="M.275 23.947v-4.714L18 7.294v4.817L.275 23.947zm0-11.699V7.534L18 19.371v4.783L.275 12.248zM.273 41.565V37.23h2.732a6.245 6.245 0 0 1-2.186-2.374C.273 33.847 0 32.734 0 31.518c0-1.262.262-2.386.786-3.372a5.407 5.407 0 0 1 2.254-2.271c.956-.551 2.072-.826 3.347-.826h11.612v4.301H7.377c-1.047 0-1.901.321-2.561.963-.66.619-.99 1.445-.99 2.477 0 .94.25 1.743.751 2.409s1.229 1.17 2.186 1.514c.956.344 2.106.516 3.449.516h7.787v4.335l-17.726.001zm.002 18.467v-4.37l12.773-4.198L.275 47.301v-4.37L18 49.435v4.06L.275 60.032zM.273 88v-4.335H3.04A5.851 5.851 0 0 1 .82 81.6C.273 80.728 0 79.696 0 78.503c0-1.422.296-2.604.888-3.544.569-.94 1.389-1.617 2.459-2.03-.956-.505-1.753-1.273-2.391-2.305C.319 69.569 0 68.365 0 67.011c0-1.262.25-2.374.751-3.337.501-.963 1.207-1.697 2.117-2.202.911-.528 1.947-.791 3.108-.791h12.022v4.335H7.172c-1.093 0-1.947.287-2.561.86-.638.574-.956 1.376-.956 2.409 0 1.239.49 2.202 1.469 2.89.956.665 2.243.998 3.859.998h9.016v4.335H7.172c-1.093 0-1.947.287-2.561.86-.638.573-.956 1.376-.956 2.409 0 .803.228 1.502.683 2.099.455.596 1.081 1.044 1.878 1.342.797.298 1.73.447 2.8.447h8.982V88H.273z" />
    </svg>
  );
}
