export default function AttIcon({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 144 144"
      fill={color || "#000000"}
      className={className}
      width={26}
      height={26}
    >
      <path d="M27.849 128.868C40.057 138.321 55.378 144 72 144c18.188 0 34.773-6.755 47.433-17.849.151-.133.076-.227-.074-.133-5.679 3.793-21.868 12.076-47.358 12.076-22.151 0-36.151-4.944-44.057-9.358-.152-.076-.208.038-.095.132m49.038 3.679c17.717 0 37.188-4.83 48.829-14.397 3.19-2.602 6.227-6.075 8.944-10.736 1.566-2.679 3.094-5.867 4.34-8.999.057-.15-.037-.226-.15-.057-10.831 15.944-42.188 25.886-74.566 25.886-22.886 0-47.51-7.32-57.15-21.301-.094-.132-.189-.076-.132.076 8.979 19.094 36.224 29.528 69.885 29.528m-19.359-31.678c-36.849 0-54.227-17.17-57.377-28.887C.113 71.811 0 71.85 0 72c0 3.943.396 9.038 1.075 12.415.321 1.641 1.661 4.227 3.623 6.284 8.925 9.302 31.17 22.339 69.698 22.339 52.49 0 64.49-17.49 66.942-23.245C143.093 85.679 144 78.245 144 72c0-1.51-.039-2.717-.096-3.905 0-.188-.113-.208-.15-.018-2.623 14.074-47.471 32.792-86.226 32.792M6.943 41.113C4.831 45.302 2.49 52.378 1.792 56.038c-.301 1.566-.17 2.321.378 3.49 4.414 9.378 26.754 24.378 78.868 24.378 31.792 0 56.489-7.811 60.49-22.074.736-2.624.773-5.396-.171-9.132-1.056-4.171-3.036-9.038-4.716-12.454-.057-.112-.151-.094-.132.037C137.132 58.98 85 71.037 58.698 71.037c-28.49 0-52.264-11.358-52.264-25.696 0-1.378.284-2.755.642-4.19.037-.132-.076-.151-.133-.038m112.604-22.962c.301.472.453.982.453 1.661 0 8-24.472 22.15-63.434 22.15-28.623 0-33.982-10.622-33.982-17.378 0-2.415.925-4.887 2.963-7.396.113-.151.019-.207-.113-.094a72.211 72.211 0 0 0-10.132 10.529C13.868 29.435 12.98 31.038 12.98 32c0 14 35.095 24.15 67.906 24.15 34.962 0 50.566-11.413 50.566-21.451 0-3.586-1.396-5.68-4.962-9.736-2.321-2.642-4.51-4.793-6.83-6.907-.113-.094-.189-.018-.113.095m-10.717-8C98.037 3.68 85.509 0 72 0 58.395 0 45.49 3.812 34.66 10.453c-3.246 2.001-5.075 3.604-5.075 5.661 0 6.075 14.188 12.604 39.357 12.604 24.906 0 44.227-7.152 44.227-14.038.001-1.642-1.433-2.794-4.339-4.529" />
    </svg>
  );
}
