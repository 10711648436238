"use client";

import { usePathname, useRouter } from "next/navigation";
import { animatePageOut } from "@/components/animations";
import { ReactNode, useState } from "react";
import { useStore } from "@/app/store";
import { resolveFromHref } from "@/app/theme-utils";

export default function TransitionLink({
  href,
  children,
  onNav,
}: {
  href: string;
  children: ReactNode;
  onNav: () => void;
}) {
  const router = useRouter();
  const pathname = usePathname();
  const {
    setNextSiteTheme,
    setNavbarExpanded,
    isInTransition,
    setIsTransitioning,
  } = useStore();

  const handleClick = () => {
    if (pathname === href) return;
    setIsTransitioning(true);
    onNav();
    setNextSiteTheme(resolveFromHref(href));
    if (pathname !== "/") setNavbarExpanded(false);
    setTimeout(() => {
      animatePageOut(href, router);
      setIsTransitioning(false);
    }, 2000);
  };

  return (
    <button
      aria-label="Navigate Home"
      disabled={isInTransition}
      className="w-full min-w-max"
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
