import { motion, useAnimate } from "framer-motion";
import { useEffect } from "react";

export default function MenuIcon({
  color,
  className,
  expanded,
}: {
  color?: string;
  className?: string;
  expanded: boolean;
}) {
  const [scopeTop, animateTop] = useAnimate();
  const [scopeBottom, animateBottom] = useAnimate();

  useEffect(() => {
    if (expanded === true) {
      animateTop(
        scopeTop.current,
        {
          rotate: 45,
          marginBottom: "0px",
          top: "calc(50% - 0px)",
        },
        { duration: 1 }
      );
      animateBottom(
        scopeBottom.current,
        {
          rotate: -45,
          marginTop: "0px",
        },
        { duration: 1 }
      );
    } else {
      animateTop(
        scopeTop.current,
        {
          rotate: 0,
          marginBottom: "4px",
          top: "calc(50% - 4px)",
        },
        { duration: 1 }
      );
      animateBottom(
        scopeBottom.current,
        {
          rotate: 0,
          marginTop: "3px",
        },
        { duration: 1 }
      );
    }
  }, [expanded]);
  return (
    <div className="flex flex-col items-center justify-center ">
      <motion.svg
        ref={scopeTop}
        width="17"
        height="4"
        viewBox="0 0 17 4"
        fill={color || "#000000"}
        style={{ marginBottom: "0px", top: "calc(50% - 8px)" }}
        className={"absolute "}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 0.5L16.5 0.500001L16.5 3.5L0.5 3.5L0.5 0.5Z"
          fill={color || "#000000"}
        />
      </motion.svg>
      <motion.svg
        ref={scopeBottom}
        width="17"
        height="4"
        viewBox="0 0 17 4"
        className={"absolute top-[50%]"}
        fill={color || "#000000"}
        style={{ marginTop: "3px" }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 0.5L16.5 0.500001L16.5 3.5L0.5 3.5L0.5 0.5Z"
          fill={color || "#000000"}
        />
      </motion.svg>
    </div>
  );
}
