import { usePathname } from "next/navigation";

import MenuIcon from "@/components/icons/menu-icon";
import { useStore } from "@/app/store";
import MenuIconMobile from "../icons/menu-icon-mobile";
import { AnimatePresence, motion } from "framer-motion";

const themes = [
  {
    uri: "/",
    color: "#0057BB",
  },
  {
    uri: "/connex",
    color: "#394ABB",
  },
  {
    uri: "/developer-platform",
    color: "#5E40BB",
  },
  {
    uri: "/innovation-lab",
    color: "#B737AA",
  },
];

export default function MobileNavButton() {
  const pathname = usePathname();

  const { setExpanded, expanded, contactFormVisible } = useStore((state) => {
    return {
      setExpanded: state.setNavbarExpanded,
      expanded: state.navbarExpanded,
      contactFormVisible: state.contactFormVisible,
    };
  });

  return (
    <AnimatePresence>
      {!contactFormVisible && (
        <motion.button
          initial={{ opacity: 0, bottom: "-60px" }}
          animate={{ opacity: 1, bottom: "25px" }}
          exit={{ opacity: 0, bottom: "-25px" }}
          aria-label="Open menu"
          onClick={() => {
            setExpanded(!expanded);
          }}
          className={` lg:hidden ${
            pathname === "/" ? "hidden" : ""
          } flex flex-col  items-center justify-center z-50 w-10 h-10 rounded-full transition-all ease-mvnx-in-out duration-[1500ms] ${
            expanded ? "-translate-y-16" : ""
          } ${
            pathname === "/about"
              ? expanded
                ? "bg-white"
                : "bg-mvnx-primary"
              : "bg-white"
          }`}
          style={{
            position: "fixed",
            left: "50%",
            bottom: "25px",
            marginLeft: "-25px",
            zIndex: 51,
          }}
        >
          <MenuIconMobile
            color={
              pathname === "/about"
                ? expanded
                  ? "#0057BB"
                  : "white"
                : "#white"
            }
            className="mx-auto rotate-90"
            expanded={expanded}
          />
        </motion.button>
      )}
    </AnimatePresence>
  );
}
